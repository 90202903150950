.heading {
  h1 {
    display: block;
    width: 100%;
    font-family: "SuisseIntl";
    font-style: normal;
    font-weight: 600;
    font-size: 54px;
    line-height: 120%;
    letter-spacing: -0.018em;
    color: #fff;
    margin: 0;
    padding: 0;
    position: relative;
    z-index: 2;

    span {
      color: #ffdb3b;
    }
  }

  h2 {
    font-family: "SuisseIntl";
    font-style: normal;
    font-weight: 600;
    font-size: 45px;
    line-height: 110%;
    letter-spacing: -1.5px;
    margin-bottom: 70px;
  }

  h3 {
    font-family: "SuisseIntl";
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 120%;
    letter-spacing: -0.5px;
    color: #ffffff;
    position: relative;
    z-index: 2;

    span {
      color: #ffdb3b;
    }
  }

  h4 {
    display: block;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 120%;
    letter-spacing: -0.5px;
    color: #000000;
    margin: 20px 0 10px;
  }

  h5 {
    display: block;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 120%;
    letter-spacing: -0.5px;
    color: #fff;
    margin: 20px 0 10px;
  }
}

.btn-div {
  button {
    width: 350px;
    height: 60px;
    background: #4861fc;
    font-family: "SuisseIntl";
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 140%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
  }
}
