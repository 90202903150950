.button {
  border: 1px solid #4861fc;
  padding: 15px 20px;
  border-radius: 3px;
  background: linear-gradient(to right, black 50%, #4861fc 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all 0.5s ease-out;
  width: max-content;
}

.button:hover {
  background-position: left bottom;
  border: none;
  cursor: pointer;
}

.text {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  color: white;
  transition: all 0.6s ease-out;
}

.text:hover {
  color: white;
}
