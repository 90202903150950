.homeMain {
  .section1 {
    height: 100vh;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;

    .container {
      z-index: 2;
    }

    .videoDiv {
      video {
        min-width: 100%;
        min-height: 100%;
        position: absolute;
        top: 0;
      }
    }

    .actionDiv {
      margin-top: 60px;
    }
  }

  .section2 {
    @-webkit-keyframes scroll {
      0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
      }

      100% {
        -webkit-transform: translateX(calc(-250px * 7));
        transform: translateX(calc(-250px * 7));
      }
    }

    @keyframes scroll {
      0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
      }

      100% {
        -webkit-transform: translateX(calc(-250px * 7));
        transform: translateX(calc(-250px * 7));
      }
    }

    .slider {
      height: 100px;
      margin: auto;
      overflow: hidden;
      position: relative;
      width: 100%;
      height: 314px;
      display: flex;
      align-items: center;
      background-color: #000;
    }

    .slider .slide-track {
      -webkit-animation: scroll 40s linear infinite;
      animation: scroll 40s linear infinite;
      display: flex;
      width: calc(250px * 14);
    }

    .slider .slide {
      height: 100px;
      width: 250px;
    }
  }

  .section3 {
    background-color: #ffdb3b;
    padding: 110px 0 90px;

    .accordion {
      background-color: transparent;

      .card {
        background-color: transparent;
        border: none;
        box-shadow: none;

        .card-body {
          padding-left: calc(50% + 38px);
          padding-top: 0;
          font-family: "SuisseIntl";
          font-style: normal;
          font-weight: normal;
          font-size: 17px;
          line-height: 140%;
          letter-spacing: -0.2px;
          color: #1f1b07;
          width: calc(90% - 40px);
        }

        .collapse {
          visibility: visible;
        }

        .card-header {
          background-color: transparent;
          border: none;
          padding: 0;
        }

        .btn-link {
          background-color: transparent;
          border: none;
          box-shadow: none;
          padding: 0;
          display: flex;
          align-items: center;
          width: 100%;
          text-decoration: none;
          padding: 15px 0;
          border-top: 1px solid rgba(0, 0, 0, 0.15);
          border-radius: 0;

          img {
            width: 35px;
          }

          span {
            font-family: "SuisseIntl";
            font-weight: 600;
            font-size: 26px;
            line-height: 120%;
            letter-spacing: -0.5px;
            color: #000;
            padding-left: 50%;
            display: flex;
            align-items: center;
            column-gap: 30px;
            small {
              padding: 0;
              font-family: "SuisseIntl";
              font-style: normal;
              font-weight: normal;
              font-size: 17px;
              line-height: 110%;
            }
          }
        }
      }
    }

    .btn-div {
      margin-top: 50px;
    }
  }

  .section4 {
    padding: 120px 0 135px;
    overflow: hidden;

    .heading {
      h5 {
        color: #000;
      }
    }

    .sliderDiv {
      margin-bottom: 100px;

      .projectCard {
        width: 95%;
        margin: auto;

        .cardImg {
          width: 100%;
          height: 340px;
          background-size: cover;
          background-position: center;
        }

        p {
          display: block;
          font-style: normal;
          font-weight: normal;
          font-size: 17px;
          line-height: 140%;
          letter-spacing: -0.3px;
          color: #989898;
        }
      }
    }
  }

  .section5 {
    padding: 100px 0;
    background-color: #000;

    .heading {
      h2 {
        color: #fff;
      }
    }

    .collabDiv {
      .row {
        row-gap: 50px;

        .flex-div {
          display: flex;

          .img-div {
            width: 160px;
          }

          .text-div {
            width: calc(100% - 160px);
            padding-left: 30px;

            p {
              color: #fff;
            }
          }
        }
      }
    }

    .btn-div {
      margin-top: 100px;
    }
  }

  // .contactForm {
  //   height: auto;
  //   padding: 160px 0;
  //   background-image: url(../../assets/images/footerbg.webp);

  //   .heading {
  //     h2 {
  //       color: #fff;
  //     }
  //   }

  //   .inputDiv {
  //     input {
  //       font-family: 'SuisseIntl';
  //       font-style: normal;
  //       font-weight: normal;
  //       font-size: 17px;
  //       line-height: 140%;
  //       letter-spacing: -0.3px;
  //       background-color: transparent;
  //       width: 100%;
  //       border-bottom: 1px solid hsla(0, 0%, 100%, .25);
  //       padding: 20px 0;
  //       color: #ffdb3b;
  //       outline: none;

  //       &:focus {
  //         border-color: #ffdb3b;
  //       }
  //     }

  //     svg {
  //       margin-right: 12px;
  //       width: 22px;
  //       height: 22px;
  //       fill: #FFDB3B;
  //       color: #FFDB3B;
  //     }

  //     &.file {
  //       position: relative;
  //       margin-top: 20px;

  //       input {
  //         position: absolute;
  //         top: 0;
  //         visibility: hidden;
  //       }

  //       .textDiv {
  //         h6 {
  //           font-family: 'SuisseIntl';
  //           font-style: normal;
  //           font-weight: normal;
  //           font-size: 17px;
  //           line-height: 140%;
  //           letter-spacing: -0.3px;
  //           margin: 0;
  //           color: #FFFFFF;
  //           cursor: pointer;
  //         }

  //         p {
  //           font-family: 'SuisseIntl';
  //           font-style: normal;
  //           font-weight: normal;
  //           font-size: 17px;
  //           line-height: 140%;
  //           letter-spacing: -0.3px;
  //           margin: 0;
  //           color: #737373;
  //           ;
  //           cursor: pointer;
  //         }
  //       }
  //     }
  //   }

  //   .privacy {
  //     p {
  //       margin-top: 20px;
  //       font-weight: 400;
  //       margin-bottom: 0;
  //       color: hsla(0, 0%, 100%, .4);
  //       font-size: 12px;
  //       line-height: 20px;

  //       a {
  //         color: #ffdb3b;
  //       }
  //     }

  //     .checkbox {
  //       position: relative;
  //       overflow: hidden;
  //       display: flex;
  //       align-items: center;
  //       column-gap: 10px;
  //       cursor: pointer;

  //       div {
  //         position: relative;
  //         height: max-content;
  //       }

  //       p {
  //         font-style: normal;
  //         font-weight: normal;
  //         font-size: 13px;
  //         line-height: 20px;
  //         color: #8F8F97;
  //         margin: 0;
  //         margin-top: -3px;
  //       }
  //     }

  //     .checkbox__input {
  //       position: absolute;
  //       visibility: hidden;
  //     }

  //     .checkbox__inner {
  //       display: inline-block;
  //       width: 16px;
  //       height: 16px;
  //       border-radius: 50%;
  //       border: 1px solid #626262;
  //       background: transparent no-repeat center;
  //     }

  //     .checkbox__input:checked+.checkbox__inner {
  //       border-color: #FFDB3B;
  //       background-color: #FFDB3B;
  //       background-size: 14px 10px;
  //     }
  //   }

  //   .btn-div {
  //     margin-top: 50px;

  //     button {
  //       width: 100%;
  //     }
  //   }
  // }

  // footer {
  //   background-color: #000;
  //   padding: 120px 0;

  //   .heading {
  //     h2 {
  //       color: #fff;
  //     }

  //     h5 {
  //       margin: 0;
  //       margin-bottom: 5px;

  //       a {
  //         color: #fff;
  //         text-decoration: none;
  //       }
  //     }

  //     ul {
  //       display: flex;
  //       flex-direction: column;
  //       padding: 0;
  //       margin: 0;

  //       li {
  //         display: inline-block;
  //         font-family: "SuisseIntl";
  //         font-style: normal;
  //         font-weight: normal;
  //         font-size: 20px;
  //         line-height: 140%;
  //         color: #ffffff;
  //         opacity: 0.5;
  //       }
  //     }
  //   }

  //   .socioIcons {
  //     display: flex;
  //     column-gap: 30px;
  //     margin-top: 40px;
  //   }

  //   .footerLast {
  //     margin-top: 70px;
  //     .text-div {
  //       span {
  //         display: block;
  //         font-family: "SuisseIntl";
  //         font-style: normal;
  //         font-weight: normal;
  //         font-size: 13px;
  //         line-height: 140%;
  //         color: #8f8f97;
  //       }
  //     }

  //     .certIcons {
  //       display: flex;
  //       column-gap: 30px;
  //     }
  //   }
  // }
}
