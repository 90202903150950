footer {
  background-color: #000;
  padding: 120px 0;

  .heading {
    h2 {
      color: #fff;
    }

    h5 {
      margin: 0;
      margin-bottom: 5px;

      a {
        color: #fff;
        text-decoration: none;
      }
    }

    ul {
      display: flex;
      flex-direction: column;
      padding: 0;
      margin: 0;

      li {
        display: inline-block;
        font-family: "SuisseIntl";
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 140%;
        color: #ffffff;
        opacity: 0.5;
      }
    }
  }

  .socioIcons {
    display: flex;
    column-gap: 30px;
    margin-top: 40px;
  }

  .footerLast {
    margin-top: 70px;
    .text-div {
      span {
        display: block;
        font-family: "SuisseIntl";
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 140%;
        color: #8f8f97;
      }
    }

    .certIcons {
      display: flex;
      column-gap: 30px;
    }
  }
}
