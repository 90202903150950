header {
    height: 60px;
    background-color: #000;
    display: flex;
    align-items: center;
    z-index: 99;
    position: sticky;
    top: 0;

    .flex-div {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .logoDiv {
            position: relative;
            z-index: 2;

            img {
                width: 80px;
            }
        }

        .navlinks {
            display: flex;
            align-items: center;

            .mainLinks {
                padding: 0;
                margin: 0;
                display: flex;
                align-items: center;

                li {
                    padding: 0;
                    margin: 0;
                    display: inline-block;

                    a {
                        font-family: 'SuisseIntl';
                        font-style: normal;
                        font-weight: normal;
                        font-size: 17px;
                        line-height: 100%;
                        letter-spacing: -0.3px;
                        color: #FFFFFF;
                        padding: 10px 10px;
                        text-decoration: none;
                        position: relative;
                        display: flex;
                        align-items: center;
                        column-gap: 5px;

                        .optionsMain {
                            display: none;
                            position: absolute;
                            top: 60px;
                            left: 0;
                            height: 470px;
                            width: 250px;
                            z-index: 3;

                            .optionUl {
                                padding: 0;
                                margin: 0;
                                display: flex;
                                flex-direction: column;

                                li {
                                    display: inline-block;
                                    padding: 0;
                                    margin: 0;
                                    padding: 5px 0;
                                    margin-bottom: 5px;
                                    width: 250px;
                                    position: relative !important;

                                    a {
                                        font-family: 'SuisseIntl';
                                        font-style: normal;
                                        font-weight: normal;
                                        font-size: 17px;
                                        line-height: 140%;
                                        letter-spacing: -0.3px;
                                        color: #8F8F97;
                                        padding: 0;
                                        padding-left: 20px;
                                        width: max-content;
                                        min-width: 0%;
                                        display: flex;
                                        align-items: center;
                                        justify-content: space-between;
                                        position: initial !important;
                                        transition: 0.7s;
                                        column-gap: 5px;

                                        ul {
                                            display: none !important;
                                            padding: 0;
                                            margin: 0;
                                            width: 250px;
                                            position: absolute;
                                            right: -250px;
                                            display: flex;
                                            flex-direction: column;
                                            top: 0;

                                            a {
                                                width: 100%;
                                            }
                                        }

                                        &:hover {
                                            min-width: 100%;
                                            color: #FFDB3B;

                                            ul {
                                                display: flex !important;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        &:hover {
                            color: #FFDB3B;
                        }
                    }

                    .beforeHead {
                        display: none;
                        width: 100%;
                        position: absolute;
                        left: 0;
                        top: 40px;
                        height: 500px;
                        background-color: black;
                    }

                    &:hover {
                        .beforeHead {
                            display: block;
                        }

                        a {
                            .optionsMain {
                                display: block;
                            }
                        }
                    }
                }
            }

            .headBtn {
                button {
                    border: 1px solid #fff;
                    background-color: transparent;
                    transition: background-color .3s, color .3s;
                    margin: 0 30px 0 15px;
                    font-family: 'SuisseIntl';
                    font-style: normal;
                    font-weight: normal;
                    font-size: 17px;
                    line-height: 100%;
                    letter-spacing: -0.3px;
                    color: #FFFFFF;
                    padding: 10px 20px;

                    &:hover {
                        border: 1px solid #4861FC;
                        background-color: #4861FC;
                        color: #fff;
                        transition: background-color .3s, color .3s;
                        border: 1px solid #4861FC;
                        background-color: #4861FC;
                        color: #fff;
                        transition: background-color .3s, color .3s;
                    }
                }
            }
        }
    }
}