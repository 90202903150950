.bgimage {
  width: 100%;
  font-family: "SuisseIntl";
  height: 600px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  h1 {
    color: white;
    text-shadow: 2px 2px #333;
  }
  .button {
    border: 1px solid black;
    padding: 15px 20px;
    border-radius: 3px;
    background: linear-gradient(to right, black 50%, #4861fc 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: all 0.5s ease-out;
    width: max-content;
  }

  .button:hover {
    background-position: left bottom;
    cursor: pointer;
  }

  .text {
    text-align: center;
    font-size: 20px;
    line-height: 30px;
    color: white;
    transition: all 0.6s ease-out;
    // display: block;
  }

  .text:hover {
    color: white;
  }
}
