.contactForm {
  height: auto;
  padding: 160px 0;
  background-image: url(../../assets/images/footerbg.webp);

  .heading {
    h2 {
      color: #fff;
    }
  }

  .inputDiv {
    input {
      font-family: "SuisseIntl";
      font-style: normal;
      font-weight: normal;
      font-size: 17px;
      line-height: 140%;
      letter-spacing: -0.3px;
      background-color: transparent;
      width: 100%;
      border-bottom: 1px solid hsla(0, 0%, 100%, 0.25);
      padding: 20px 0;
      color: #ffdb3b;
      outline: none;

      &:focus {
        border-color: #ffdb3b;
      }
    }

    svg {
      margin-right: 12px;
      width: 22px;
      height: 22px;
      fill: #ffdb3b;
      color: #ffdb3b;
    }

    &.file {
      position: relative;
      margin-top: 20px;

      input {
        position: absolute;
        top: 0;
        visibility: hidden;
      }

      .textDiv {
        h6 {
          font-family: "SuisseIntl";
          font-style: normal;
          font-weight: normal;
          font-size: 17px;
          line-height: 140%;
          letter-spacing: -0.3px;
          margin: 0;
          color: #ffffff;
          cursor: pointer;
        }

        p {
          font-family: "SuisseIntl";
          font-style: normal;
          font-weight: normal;
          font-size: 17px;
          line-height: 140%;
          letter-spacing: -0.3px;
          margin: 0;
          color: #737373;
          cursor: pointer;
        }
      }
    }
  }

  .privacy {
    p {
      margin-top: 20px;
      font-weight: 400;
      margin-bottom: 0;
      color: hsla(0, 0%, 100%, 0.4);
      font-size: 12px;
      line-height: 20px;

      a {
        color: #ffdb3b;
      }
    }

    .checkbox {
      position: relative;
      overflow: hidden;
      display: flex;
      align-items: center;
      column-gap: 10px;
      cursor: pointer;

      div {
        position: relative;
        height: max-content;
      }

      p {
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 20px;
        color: #8f8f97;
        margin: 0;
        margin-top: -3px;
      }
    }

    .checkbox__input {
      position: absolute;
      visibility: hidden;
    }

    .checkbox__inner {
      display: inline-block;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      border: 1px solid #626262;
      background: transparent no-repeat center;
    }

    .checkbox__input:checked + .checkbox__inner {
      border-color: #ffdb3b;
      background-color: #ffdb3b;
      background-size: 14px 10px;
    }
  }

  .btn-div {
    margin-top: 50px;

    button {
      width: 100%;
    }
  }
}
